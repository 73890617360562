export const orderBy = (data, sort, order) => {
  const newData = [...data];
  return newData.sort((a, b) => {
    if (order === 'asc') {
      return a[sort] - b[sort];
    } else {
      return b[sort] - a[sort];
    }
  });
};
