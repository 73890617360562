<template>
  <div>
    <el-upload
      :show-file-list="false"
      class="upload-demo"
      :class="{ disabled: inputNotWrite }"
      action=""
      :disabled="disabled"
      :http-request="uploadSectionFile"
    >
      <el-button :size="btnSize" plain v-if="type === 'button'">
        点击上传
      </el-button>
      <el-button :size="btnSize" v-if="type === 'img'" style="padding:0;border:none;">
        <img :src="require('@/assets/upload-img.png')" alt="" />
      </el-button>
      <div slot="tip" class="mt16 mb16" style="color:#8c8c8c;font-size:12px;" v-if="showTip">
        <slot name="tips">
          <span>只能上传jpg/png文件，且不超过500kb</span>
          <span v-if="max">，最多{{ max }}张</span>
        </slot>
      </div>
    </el-upload>
  </div>
</template>

<script>
import { hexMD5 } from '@/helper/md5';
export default {
  props: {
    // 上传按钮类型
    type: {
      type: String,
      default: 'button',
    },
    // 上传文件保存子路径
    path: {
      type: String,
      default: '/tb_goods',
    },
    fileContent: {
      type: String || Number,
      default: '',
    },
    max: {
      type: Number,
      default: 0,
    },
    nowImages: {
      type: Array,
      default: () => [],
    },
    showTip: {
      type: Boolean,
      default: true,
    },
    btnSize: {
      type: String,
      default: 'small',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      canUp: true,
    };
  },
  computed: {
    inputNotWrite() {
      // 0是查看 不可以修改
      return this.disabled || this.$store.state.inputType === 0;
    },
  },
  methods: {
    uploadSectionFile(params) {
      const that = this;
      if (!this.canUp) {
        this.$message.error('请等待当前上传任务结束');
        return;
      }
      if (this.max && this.nowImages.length >= this.max) {
        this.$message.error('图片到达了最大数量');
        return;
      }
      const ossConfig = {
        // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
        region: process.env.VUE_APP_REGION,
        // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
        accessKeyId: process.env.VUE_APP_OSSACCESSKEY,
        accessKeySecret: process.env.VUE_APP_OSSSECRETKEY,
        bucket: process.env.VUE_APP_OSSBUCKET,
      };
      const client = new OSS(ossConfig);
      const newFileName = MD5FileName(params.file.name);

      function MD5FileName(oldname) {
        const lastIndex = oldname.lastIndexOf('.');
        const name = oldname.slice(0, lastIndex);
        const mine = oldname.slice(lastIndex);
        const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g');
        return (reg.test(name) ? hexMD5(name) : name) + mine;
      }

      async function multipartUpload() {
        try {
          const fileName = `/public${that.path}${
            that.fileContent ? '/' + that.fileContent : ''
          }/${that.$moment().unix()}${newFileName}`;
          const result = await client.multipartUpload(fileName, params.file, {
            meta: { year: `${that.$moment().format('YYYY')}`, people: 'brow' },
            mime: 'image/jpeg',
          });
          const { res } = result;
          const { status, statusCode } = res;
          if (status === 200 && statusCode === 200) {
            that.$message.success('上传成功');
            that.$emit('addSuccess', `${process.env.VUE_APP_FILE}${fileName}`);
            that.canUp = true;
          } else {
            that.canUp = true;
          }
        } catch (e) {
          console.log(e);
          that.$message.error('上传失败');
          that.canUp = true;
        }
      }

      // 开始分片上传。
      multipartUpload();
    },
  },
};
</script>

<style lang="less"></style>
