<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import { getGroup } from '@/helper/index.js';
export default {
  async mounted() {
    this.$store.commit('SET_SCROLLWIDTH', document.body.scrollWidth);
    if (this.$store.state.token) {
      this.$store.dispatch('getAccountInfo');
    }
    // 获取不同渠道的商品分类列表
    // const [groupJson] = await Promise.all([getGroup()]).catch(() => {
    //   this.$message.error('商品信息获取失败');
    // });
    // this.$store.dispatch('getChannelSource');
    // this.$store.commit('WRITE_GOODS_CATEGORY', groupJson);
  },
};
</script>
<style lang="less"></style>
