/**
  selectKeys  搜索条件数组
    @param {string} label 名称
    @param {string} type 输入框类型
    @param {string} model 搜索值
    @param {string} prop 搜索参数名
    @param {string} content 用于label显示拓展的属性名
    @param {array} options 用于表单元素options属性
    @param {array} concat 用于表单元素options属性
    @param {string} source 从store中获取的列表数据键名，用于options
    @param {object} props 用于区别从concat,options,source中获取的列表数据的lable和value显示
    @param {array} relevance 此输入框的关联输入框的prop属性，多用于select类型输入框
    @param {string} beforeSource 从store中获取数据的路径，与relevance属性配合使用，详见于relevanceHandler方法
    @param {array} transProp datetimerange类型输入框时间选择器值转变的参数名列表
*/
/**
  selectBtns  搜索按钮数组
    @param {string} label 按钮名称
    @param {string} method 按钮触发的emit名
    @param {string} align 按钮排列方式
    @param {boolean} plain 是否朴素按钮
    @param {string} marginLeft 按钮外边距
*/
module.exports = () => {
  return {
    uneditedGoods: {
      selectKeys: [
        { label: '淘宝商品ID', type: 'input', model: '', prop: 'taobao_item_id' },
        { label: '商品名称', type: 'input', model: '', prop: 'title' },
      ],
      selectBtns: [
        { label: '搜索', method: 'searchBtn' },
        { label: '批量结算价导入', method: 'someSettlementImport', align: 'left', marginLeft: '0' },
        { label: '结算价导入', method: 'settlementImport', align: 'left', plain: true },
        { label: '售价导入', method: 'costImport', align: 'left' },
      ],
    },
    warehouseGoods: {
      selectKeys: [
        { label: '淘宝商品ID', type: 'input', model: '', prop: 'taobao_item_id' },
        { label: '商品名称', type: 'input', model: '', prop: 'title' },
        { type: 'br' },
        {
          label: '渠道筛选',
          type: 'select',
          prop: 'source',
          source: 'shopSource',
          concat: [{ source_name: '全部', source: '' }],
          props: {
            label: 'source_name',
            value: 'source',
          },
          relevance: ['shop_pk_id'],
          model: '',
        },
        {
          label: '店铺筛选',
          type: 'select',
          prop: 'shop_pk_id',
          source: '',
          beforeSource: 'sourceShopList',
          concat: [{ shop_name: '全部', id: '' }],
          props: {
            label: 'shop_name',
            value: 'id',
          },
          model: '',
        },
        // { label: '商品类目', type: 'select', model: '', options: [{ label: 'ceshi', value: '' }] },
      ],
      selectBtns: [
        // { label: '重置', method: 'resetKeys' },
        { label: '搜索', method: 'searchBtn' },
      ],
    },
    sellGoods: {
      selectBtns: [
        // { label: '重置', method: 'resetKeys' },
        { label: '搜索', method: 'searchBtn' },
      ],
    },
    soldGoods: {
      selectKeys: [
        { label: '商品名称', type: 'input', model: '' },
        { label: '淘宝订单号', type: 'input', model: '' },
        { label: '渠道订单号', type: 'input', model: '' },
        { label: '联系电话', type: 'input', model: '' },
        { label: '订单状态', type: 'select', model: '', options: [{ label: '待审核', value: '' }] },
      ],
      selectBtns: [{ label: '搜索', method: 'searchBtn' }],
    },
    afterSale: {
      selectBtns: [
        // { label: '重置', method: 'resetKeys' },
        { label: '搜索', method: 'searchBtn' },
      ],
    },
    storeList: {
      selectKeys: [{ label: '店铺名称', type: 'input', model: '', prop: 'shop_name' }],
      selectBtns: [{ label: '搜索', method: 'searchBtn' }],
    },
    pushShipping: {
      selectKeys: [
        { label: '渠道订单号', type: 'input', model: '', prop: 'source_order_id' },
        { label: '淘宝订单号', type: 'input', model: '', prop: 'publish_order_id' },
        { label: `商品标题`, type: 'input', model: '', prop: 'goods_name' },
        { label: '渠道商品ID', type: 'input', model: '', prop: 'publish_goods_id' },
        { label: '淘宝商品ID', type: 'input', model: '', prop: 'source_goods_id' },
        {
          label: '渠道筛选',
          type: 'select',
          prop: 'source',
          source: 'shopSource',
          concat: [{ source_name: '全部', source: 'all' }],
          props: {
            label: 'source_name',
            value: 'source',
          },
          model: 'all',
          defaultModel: 'all',
        },
      ],
      selectBtns: [{ label: '搜索', method: 'searchBtn' }],
    },
    unusual: {
      selectKeys: [
        {
          label: '订单号',
          type: 'input',
          model: '',
          prop: 'source_order_id',
          content: 'sourceName',
        },
        { label: '淘宝订单号', type: 'input', model: '', prop: 'publish_order_id' },
        { label: `商品标题`, type: 'input', model: '', prop: 'goods_name' },
        {
          label: '商品ID',
          type: 'input',
          model: '',
          prop: 'publish_goods_id',
          content: 'sourceName',
        },
        { label: '淘宝商品ID', type: 'input', model: '', prop: 'source_goods_id' },
        {
          label: '人工处理状态',
          type: 'select',
          model: '',
          prop: 'deal_type',
          defaultModel: '',
          concat: [
            { label: '全部', value: '' },
            { label: '未处理', value: 'untreated' },
            { label: '处理中', value: 'doing' },
            { label: '已处理', value: 'done' },
          ],
        },
        {
          label: '下单时间',
          type: 'datetimerange',
          model: '',
          prop: 'time',
          transProp: ['start_time', 'end_time'],
          content: 'sourceName',
        },
        // {
        //   label: '订单异常原因',
        //   type: 'select',
        //   model: '',
        //   prop: 'type',
        //   concat: [
        //     { label: '全部', value: '' },
        //     { label: '全部', value: '' },
        //     { label: '全部', value: '' },
        //   ],
        // },
        // {
        //   label: '去淘宝下单失败异常原因',
        //   type: 'select',
        //   model: '',
        //   concat: [{ label: '全部', value: '' }],
        // },
      ],
      selectBtns: [{ label: '搜索', method: 'searchBtn' }],
    },
    publishedGoods: {
      selectKeys: [
        {
          label: `渠道商品ID`,
          type: 'input',
          model: '',
          prop: 'goods_id',
        },
        { label: '淘宝商品ID', type: 'input', model: '', prop: 'taobao_goods_id' },
        { label: '商品名称', type: 'input', model: '', prop: 'title' },
        {
          label: '商品类目',
          type: 'cascader',
          model: '',
          options: [],
          prop: 'dy_cid',
          source: 'goodsCategory.DouYin',
          beforeSource: 'goodsCategory',
          extra: { checkStrictly: true },
        },
        {
          label: '渠道筛选',
          type: 'select',
          prop: 'source',
          source: 'shopSource',
          concat: [{ source_name: '全部', source: 'all' }],
          props: {
            label: 'source_name',
            value: 'source',
          },
          model: 'all',
          defaultModel: 'all',
          relevance: ['dy_cid'],
        },
        {
          label: '库存更新时间',
          type: 'datetimerange',
          prop: 'time',
          transProp: ['quantity_update_start', 'quantity_update_end'],
          model: '',
        },
      ],
      selectBtns: [{ label: '搜索', method: 'searchBtn' }],
    },
    merchantData: {
      selectKeys: [
        {
          label: '商家手机账号',
          type: 'input',
          model: '',
          prop: 'shop_mobile',
        },
        { label: '商家店铺名称', type: 'input', model: '', prop: 'shop_name' },
      ],
      selectBtns: [{ label: '搜索', method: 'searchBtn' }],
    },
    logistics: {
      selectKeys: [
        {
          label: '淘宝物流公司',
          type: 'input',
          model: '',
          prop: 'name',
        },
      ],
      selectBtns: [{ label: '搜索', method: 'searchBtn' }],
    },
  };
};
