const Vue = require('vue');
const VueRouter = require('vue-router');
import brand from './brand.js';

Vue.use(VueRouter);

export const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登陆',
    },
    component: () => import('@/pages/login'),
  },
  {
    path: '/not-bind',
    name: 'not-bind',
    meta: {
      title: '未绑定',
    },
    component: () => import('@/pages/notBind/index.vue'),
  },
  {
    path: '/select-pool',
    name: 'select-pool',
    meta: {
      title: '选品池',
    },
    component: () => import('@/pages/selectPool/index.vue'),
  },
  {
    path: '/',
    // redirect: 'home',
    component: () => import('@/pages/layout'),
    children: [
      // {
      //   path: '/home',
      //   name: 'home',
      //   meta: {
      //     title: '首页',
      //   },
      //   component: () => import('@/pages/home'),
      // },
      ...brand,
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
  base: '/',
});

export default router;
