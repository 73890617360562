const Vue = require('vue');
const Vuex = require('vuex');
import * as actions from './action';
import * as getters from './getter';
import state from './state';
import mutations from './mutation';
import createLogger from 'vuex/dist/logger';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';
const store = new Vuex.Store({
  actions,
  getters,
  state,
  mutations,
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});

export default store;
