const mutations = {
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
    window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
  },
  SET_NOTIFYCOUNTS: (state, notifyCounts) => {
    state.notifyCounts = notifyCounts;
  },
  SET_SCROLLWIDTH: (state, scrollWidth) => {
    state.scrollWidth = scrollWidth;
  },
  SET_TOKEN: (state, data) => {
    state.token = data;
    window.localStorage.setItem('token', data);
  },
  SET_UID: (state, data) => {
    state.token = data;
    window.localStorage.setItem('uid', data);
  },
  INPUTUNNOTWRITE: (state, data) => {
    state.inputType = data;
  },
  CLASSIFICATION: (state, data) => {
    state.classification = data;
  },
  WRITE_GOODS_CATEGORY(state, data) {
    state.goodsCategory = data;
  },
  SET_SHOP_SOURCE(state, data) {
    state.shopSource = data;
  },
  SET_SHOP_SOURCE_OBJ(state, data) {
    state.shopSourceObj = data;
  },
  SET_EDIT_SHOP_BRAND_STORE(state, data) {
    state.editShopBrandStore = data[0];
    state.sourceShopList = data[1];
  },
  set_dyRefundStatusList(state, obj) {
    if (!state.dyRefundStatusList[obj.source]) {
      state.dyRefundStatusList[obj.source] = obj.data;
    }
  },
  set_dyRefundTypeList(state, obj) {
    if (!state.dyRefundTypeList[obj.source]) {
      state.dyRefundTypeList[obj.source] = obj.data;
    }
  },
  SET_MERCHANTTYPE(state, value) {
    state.merchantType = value;
  },
  SET_SELECTPOOLCATEGORY(state, value) {
    state.selectPoolGoodsCategory = value;
  },
};

export default mutations;
