<template>
  <div class="stairs">
    <div v-for="(item, index) in stairsList" :key="item.name" class="stairs-item">
      <a :href="item.href" :style="`color:${index === 0 ? '#fff' : 'inhert'}`">
        {{ index + 1 + '.' + item.name }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stairsList: {
      type: Array,
      default: () => [],
      require: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.stairs {
  display: flex;
  margin-bottom: 24px;
  .stairs-item {
    flex: 1;
    line-height: 48px;
    text-align: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    background-color: #f7f7f7;
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      height: 0;
      width: 0;
      border-style: solid;
      border-width: 10px;
      border-right-width: 0;
      border-top-width: 24px;
      border-bottom-width: 24px;
    }
    &:before {
      left: 0;
      border-color: #f7f7f7;
      border-left-color: #fff;
    }
    &:after {
      right: 0;
      border-color: #fff;
      border-left-color: #f7f7f7;
    }
    a {
      display: block;
    }
    &:first-child {
      background-color: @color-primary;
      &:after {
        border-color: #fff;
        border-left-color: @color-primary;
      }
      &:before {
        content: none;
      }
    }
    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}
</style>
