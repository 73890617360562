<template>
  <div>
    <div class="align flex-left goods-detail mr20">
      <img :src="row.pic_url" alt="" />
      <div class="ml10">
        <div class="goods-title text-ellipsis mb10">{{ `${row.title}` }}</div>
        <div class="source-info">
          <span v-if="row.category" class="mb2">{{ `淘宝类目：${row.category}` }}</span>
          <span v-if="row.dy_categary" class="mb2">{{ `所属类目：${row.dy_categary}` }}</span>
          <div class="align flex-left mb6" v-if="showTop">
            <span v-if="row.source_goods_id">{{ `淘宝ID：${row.source_goods_id}` }}</span>
            <span v-if="row.publish_goods_id">
              {{ `抖音ID：${row.publish_goods_id}` }}
            </span>
            <span class="tag ml20" style="color: red" v-show="row.area_limit">区域限售</span>
          </div>
        </div>
        <span class="tag" style="color: red" v-show="row.error_message && showErr">
          {{ row.error_message }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * 是否显示头部信息
     */
    showTopInfo: {
      type: Boolean,
      default: true,
    },
    /**
     * 每一个商品的基本信息
     */
    row: {
      type: Object,
      default: () => {},
    },
    showErr: {
      /**
       * 是否显示订单异常信息
       */
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showTop() {
      return (
        this.showTopInfo &&
        (this.row.source_goods_id ||
          this.row.publish_goods_id ||
          this.row.category ||
          this.row.dy_categary)
      );
    },
  },
};
</script>

<style lang="less" scoped>
.flex-left.goods-detail {
  line-height: 20px;
  .goods-title {
    max-height: 40px;
    color: @color-text-primary;
  }
  .source-info {
    color: #606266;
    font-size: 12px;
  }
  img {
    width: 90px;
    height: 90px;
  }
  .tag {
    display: inline-block;
    color: #ff7a3a;
    background: #ffebe1;
    padding: 0px 10px;
    border-radius: 15px;
    font-size: 12px;
  }
}
// @media screen and(max-width:1600px) {
//   .flex-left.goods-detail {

//   }
// }
</style>
