<template>
  <div class="flex">
    <div
      :class="['pic-box', selectIndex === index ? 'active' : null]"
      :style="{ width: width + 'px' }"
      v-for="(item, index) in list"
      @click="handlerSelect(index)"
      :key="index"
    >
      <div class="pic-wrap" :class="{ 'pic-rect': modal === 'rect' }">
        <div class="pic-content">
          <img :src="item.img" alt="" />
          <div class="handlers">
            <i
              class="el-icon-view"
              style="cursor: pointer;color: #fff;font-size: 20px;cu"
              @click.stop="imageZoomIn(item.img, index)"
            ></i>
            <i v-if="!readOnly" class="iconfont icon-shanchu" @click.stop="deleteImg(index)"></i>
          </div>
        </div>
      </div>
      <slot name="info" v-if="showInfo" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImgList',
  props: {
    // 图片数组
    list: {
      type: Array,
    },
    // 图片宽度
    width: {
      type: Number,
      default: 160,
    },
    // 是否显示其他信息
    showInfo: {
      type: Boolean,
      default: false,
    },
    // 图片显示模式
    modal: {
      type: String,
      default: 'rect',
    },
    selectIndex: {
      type: Number,
      default: 0,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // selectIndex: 0,
    };
  },
  methods: {
    deleteImg(index) {
      let list = JSON.parse(JSON.stringify(this.list));
      list = list.filter((ele, i) => index !== i);
      this.$emit('update:list', list);
    },
    imageZoomIn(img, index) {
      const list = this.list.map(item => {
        return item.img;
      });
      this.$dialog.ImgZoomIn({ imageData: list || [img], index: index });
    },
    handlerSelect(index) {
      const num = index;
      this.$emit('update:selectIndex', num);
    },
  },
};
</script>

<style scoped>
.pic-box {
  width: 160px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.pic-box.active {
  border: 2px solid #476cf2;
}
.pic-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.pic-content {
  background: #ddd;
  min-height: 38px;
}
.pic-content img {
  display: block;
  cursor: pointer;
}
.pic-rect {
  padding-bottom: 100%;
}
.pic-rect .pic-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pic-rect .pic-content img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pic-content .handlers {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  display: none;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.pic-box:hover .pic-content .handlers {
  display: flex;
}

.pic-content .handlers .iconfont {
  font-size: 24px;
  color: #ddd;
  cursor: pointer;
}

.pic-box .pic-info {
  margin-top: 5px;
}

.pic-box .pic-info .title {
  line-height: 20px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
