// 注入全局的组件
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
/* 全局基础组件注册 */
const requireList = require.context('@/components', false, /[A-Z]\w+\.(vue)$/);

const plugins = {
  install(Vue) {
    requireList.keys().forEach(fileName => {
      // 获取组件配置
      const componentConfig = requireList(fileName);
      // 获取组件的 PascalCase 命名
      const componentName = upperFirst(
        camelCase(
          // 获取和目录深度无关的文件名
          fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
        )
      );
      Vue.component(
        componentName,
        // 如果这个组件选项是通过 `export default` 导出的，
        // 那么就会优先使用 `.default`，
        // 否则回退到使用模块的根。
        componentConfig.default || componentConfig
      );
    });
  },
};
export default plugins;
