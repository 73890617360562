const state = {
  // 用户信息
  userInfo: JSON.parse(window.localStorage.getItem('userInfo') || '{}'),
  // 消息中心-未读消息
  notifyCounts: 0,
  // 浏览器body的宽
  scrollWidth: 1920,
  token: window.localStorage.getItem('token'),
  uid: window.localStorage.getItem('uid'),
  classification: [], // 分类下拉列表
  // 编辑商品里的type
  // 0是不可以编辑 查看
  // 1是修改 但是还没有被保存 下边是保存和提交
  // 2也是可以修改 但是底部是修改
  inputType: -1,
  // 商品类目分类
  goodsCategory: {
    DouYin: [],
    KuaiShou: [],
  },
  // 店铺渠道的分类
  shopSource: [
    { source: 'DouYin', source_name: '抖音' },
    { source: 'KuaiShou', source_name: '快手' },
  ],
  // 渠道分类的obj
  shopSourceObj: {
    DouYin: { source: 'DouYin', source_name: '抖音' },
    KuaiShou: { source: 'KuaiShou', source_name: '快手' },
  },
  // 所有的店铺列表
  editShopBrandStore: [],
  // 每个渠道各自的店铺列表
  sourceShopList: {},
  // 售后状态列表
  dyRefundStatusList: {},
  // 售后类型列表
  dyRefundTypeList: {},
  // 用户类型
  merchantType: null,
  // 选品池商品类型
  selectPoolGoodsCategory: [],
};
export default state;
