<template>
  <div>
    <el-form class="demo-form-inline" ref="formInline" :rules="rules" :model="newInformation">
      <el-form-item
        :label="`${item.content ? content[item.content] : ''}${item.label}:`"
        v-for="(item, index) in selectKeys"
        :prop="item.prop"
        :key="index"
      >
        <!-- options 查看与欧没有添加的显示 比如全部 -->
        <FormComponents
          :type="item.type"
          v-model="newInformation[item.prop]"
          :formValues="item.model"
          placeholder="请输入内容"
          :options="
            (item.concat || []).concat(
              (item.source ? $_.get($store.state, item.source) : item.options) || []
            )
          "
          :value="$_.get(item.props, 'value', 'value')"
          :source="$_.get(item.props, 'label', 'label')"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="enter">确认</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    selectKeys: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Object,
      default: () => {},
    },
    information: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      newInformation: this.information || {},
    };
  },
  methods: {
    enter() {
      this.$emit('enter', this.newInformation);
    },
  },
  watch: {
    newInformation(newValue, oldValue) {
      this.$emit('update:information', newValue);
    },
  },
};
</script>
