<template>
  <div class="card">
    <div class="card-title" v-if="title !== ''">{{ title }}</div>
    <div class="card-wrap">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  position: relative;
  padding: 30px;
  background: #fff;
  margin-bottom: 16px;
  border-radius: 5px;
  .card-title {
    line-height: 30px;
    font-size: 20px;
    color: #333;
    margin-bottom: 20px;
  }
}
</style>
