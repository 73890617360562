import router from '@/router';
import store from '@/store';

const whiteList = ['/login'];

router.beforeEach((to, from, next) => {
  if (store.state.token) {
    if (to.path === '/login') {
      next({ path: '/' });
    } else {
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login?redirect=${to.path}`);
    }
  }
});
