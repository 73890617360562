<template>
  <div class="bffffff mb16 page-search-box">
    <slot name="top"></slot>
    <div class="flex between">
      <el-form
        class="demo-form-inline"
        ref="formInline"
        label-position="left"
        :label-width="labelWidth"
        @submit.native.prevent
      >
        <el-row>
          <el-col
            v-for="(item, index) in selectKeys"
            :key="index"
            :span="itemWidth"
            :style="{
              clear: item.type !== 'br' ? 'none' : 'both',
              marginRight: selectKeys.length - 1 === index ? '' : '70px',
            }"
          >
            <el-form-item
              v-if="item.type !== 'br'"
              :label="`${item.content ? content[item.content] : ''}${item.label}`"
              size="small"
            >
              <!-- options 查看与欧没有添加的显示 比如全部 -->
              <FormComponents
                :type="item.type"
                v-model="item.model"
                :name="item.prop"
                :formValues="item.model"
                :relevance="item.relevance"
                placeholder="请输入内容"
                :options="
                  (item.concat || []).concat(
                    (item.source ? $_.get($store.state, item.source) : item.options) || []
                  )
                "
                :value="item.props ? $_.get(item.props, 'value', null) : 'value'"
                :source="item.props ? $_.get(item.props, 'label', null) : 'label'"
                :extra="item.extra || {}"
              />
            </el-form-item>
          </el-col>
          <el-col v-if="btnsNewline"></el-col>
          <div
            class="search-box-btns"
            :style="{ float: selectBtnsAlign, width: !btnsNewline ? 'auto' : '100%' }"
          >
            <el-form-item
              v-for="(btn, index) in selectBtns"
              :key="'btn' + index"
              :label-width="'0'"
              size="small"
              :style="{ float: btn.align ? btn.align : 'right' }"
            >
              <el-button
                :type="btn.plain ? 'default' : 'primary'"
                @click="$emit(btn.method)"
                size="small"
                :plain="btn.plain"
                :style="{
                  'margin-left': btn.marginLeft || '10px',
                }"
              >
                {{ btn.label }}
              </el-button>
            </el-form-item>
            <el-form-item style="float:right" label-width="0" size="small">
              <el-button
                plain
                class="button-plain--default"
                @click="resetValues"
                style="margin-left:10px"
              >
                重置
              </el-button>
            </el-form-item>
          </div>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import selectItems from '@/data/selectItems';
export default {
  name: 'TableSelects',
  props: {
    /**
     * 每个inout的宽度
     */
    itemWidth: {
      type: Number,
      default: 6,
    },
    /**
     * label宽度
     */
    labelWidth: {
      type: String,
      default: '6em',
    },
    /**
     * 绑定的字段
     */
    bind: {
      type: String,
      default: '',
    },
    /**
     * 多个表单的数组，formComponents的组件循环
     */
    afferentItems: {
      type: Function,
      default: val => val,
    },
    /**
     * label前边添加的前缀，没有放到keys里是因为如果放到了keys里每一次更改都会进入watch，然后会进行渲染，这样逻辑是错误的
     */
    content: {
      type: Object,
      default: () => {},
    },
    /**
     * 是否需要搜索按钮换行
     */
    btnsNewline: {
      type: Boolean,
      default: false,
    },
    selectBtnsAlign: {
      type: String,
      default: 'right',
    },
  },
  data() {
    const bind = selectItems()[this.bind];
    return {
      // 每一个绑定的值
      selectKeys: bind?.selectKeys || this.afferentItems(),
      ...bind,
    };
  },
  provide() {
    return {
      // 关联属性值改变处理
      relevanceHandler: this.relevanceHandler,
    };
  },
  created() {},
  mounted() {
    this.$store.dispatch('editShopBrandStore');
    this.setOtherQuery();
  },
  methods: {
    resetValues() {
      this.selectKeys.forEach((ele, index) => {
        this.$set(ele, 'model', this.selectKeys[index].defaultModel || '');
      });
      this.setOtherQuery();
      this.$emit('searchBtn');
    },
    relevanceHandler(props, value) {
      props.forEach(prop => {
        this.selectKeys.forEach(ele => {
          if (ele.prop === prop) {
            ele.model = ele.type === 'cascader' ? [] : '';
            const beforeSource = ele.beforeSource;
            if (value !== '') {
              if (beforeSource) {
                ele.source = beforeSource + '.' + value;
              } else {
                ele.source = value;
              }
            } else {
              ele.source = '';
            }
          }
        });
      });
    },
    setOtherQuery() {
      const parent = this.$parent;
      const temp = this.$parent.tableSelectsQuery;
      this.selectKeys.forEach(v => {
        const type = v.type;
        const prop = v.prop;
        const value = v.model;
        switch (type) {
          case 'datetimerange': {
            const transProp = v.transProp;
            if ((transProp || []).length === 2) {
              const transProp = v.transProp;
              if (value && value.length) {
                parent.$set(temp, transProp[0], this.$moment(value[0]).unix());
                parent.$set(temp, transProp[1], this.$moment(value[1]).unix());
              } else {
                parent.$set(temp, transProp[0], '');
                parent.$set(temp, transProp[1], '');
              }
            }
            break;
          }
          case 'cascader': {
            const cascaderValue = v.model;
            if (cascaderValue) {
              parent.$set(temp, prop, cascaderValue[cascaderValue.length - 1]);
            } else {
              parent.$set(temp, prop, '');
            }
            break;
          }
          default:
            parent.$set(temp, prop, value);
        }
      });
    },
  },
  watch: {
    afferentItems(newValue) {
      this.selectKeys = newValue;
    },
    selectKeys: {
      handler(newValue) {
        this.setOtherQuery();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.page-search-box {
  .select-box {
    width: calc(100% - 80px);
  }
  .button-box {
    width: 80px;
  }
}
.item-label {
  text-align: right;
  line-height: 28px;
}
</style>

<style lang="less">
.demo-form-inline {
  width: 100%;
  .bottom-button {
    justify-content: flex-end;
    .el-form-item {
      margin-right: 0;
      margin-left: 10px;
    }
  }
}
</style>
