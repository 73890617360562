/* eslint-disable no-unused-vars */
import { get, post, del, put, patch } from '@/api/index';
import { groupBy } from 'lodash';
import store from './index';
import router from '@/router/index';
const { Message } = ELEMENT;
export const Login = function({ commit }, userInfo) {
  return new Promise((resolve, reject) => {
    post({
      road: 'login',
      data: userInfo,
      version: 'v0',
    }).then(res => {
      if (res.error_code === 0) {
        const { uid, token } = res.data;
        commit('SET_TOKEN', token);
        commit('SET_UID', uid);
        resolve();
      } else {
        reject(res.msg);
      }
    });
  });
};

// 登录注册忘记密码发送验证码
export const getAccountInfo = ({ commit }) => {
  get({
    road: 'accountInfo',
    version: 'v0',
  }).then(res => {
    if (res.error_code === 0) {
      const path = router.history.current.path;
      store.commit('SET_USERINFO', res.data);
      store.commit('SET_MERCHANTTYPE', res.data.ext_info.type);
      if (res.data.type === 0 && path !== '/not-bind') {
        router.push('/not-bind');
      } else if (res.data.type !== 0 && path === '/not-bind') {
        router.push('/');
      }
    } else {
      store.commit('SET_USERINFO', '');
      store.commit('SET_TOKEN', '');
    }
  });
};

// 登录注册忘记密码发送验证码
export const validateCode = ({ commit }, data) => {
  post({
    road: 'validateCode',
    data: data.params,
    version: 'v0',
  }).then(res => {
    if (res.error_code === 0) {
      if (res.data) {
        data.onSuccess();
        Message.success('验证码发送成功');
      } else {
        Message.success('验证码发送失败');
      }
    }
  });
};

export const getChannelSource = async ({ commit }) => {
  if (store.state.shopSource && store.state.shopSource.length) {
    return store.shopSource;
  }
  const shopSource = await get({
    road: 'getChannelSource',
    version: 'v0',
  });
  if (shopSource.error_code === 0) {
    const newObj = {};
    shopSource.data.forEach(ele => {
      newObj[ele.source] = ele;
    });
    commit('SET_SHOP_SOURCE', shopSource.data);
    commit('SET_SHOP_SOURCE_OBJ', newObj);
    return shopSource.data;
  }
};
export const editShopBrandStore = async ({ commit }) => {
  if (store.state.editShopBrandStore && store.state.editShopBrandStore.length) {
    return store.state.editShopBrandStore;
  }
  const data = await get({
    road: 'editShopBrandStore',
    query: {
      source: 'all',
    },
    version: 'v0',
  })
    .then(res => (res.error_code ? {} : res.data))
    .catch(err => err);
  commit('SET_EDIT_SHOP_BRAND_STORE', [data, groupBy(data, 'source')]);
  return data;
};
export const dyRefundDate = async ({ commit }) => {
  const sources = ['DouYin', 'KuaiShou'];
  const promises = sources.map(source =>
    get({
      road: 'channelConfi',
      version: 'v0',
      query: { source },
    })
  );
  const list = await Promise.all(promises);
  if (list) {
    list.map((item, index) => {
      if (item.error_code === 0) {
        const statusList = item.data.aftersale_status_config;
        const typeList = item.data.aftersale_type_config;
        commit('set_dyRefundStatusList', { source: sources[index], data: Obj2List(statusList) });
        commit('set_dyRefundTypeList', { source: sources[index], data: Obj2List(typeList) });
      }
    });
  }
  function Obj2List(data) {
    return Object.keys(data).reduce((acc, item) => {
      acc.push(data[item]);
      return acc;
    }, []);
  }
};
export const getNotifyCounts = ({ commit }) => {
  get({
    road: 'notifyCounts',
    version: 'v0',
  }).then(res => {
    if (res.error_code === 0) {
      commit('SET_NOTIFYCOUNTS', Number(res.data.total));
    }
  });
};
