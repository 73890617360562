const _ = require('lodash');
import axios from 'axios';
import baseUrl from '@/api/road';
const serialize = function(obj) {
  var str = [];
  for (var p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p) && obj[p]) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }
  return str.join('&');
};

/**
 * [camel2Snake 驼峰转蛇形]
 * @param  {String} str [description]
 * @return {String}     [description]
 */
const camel2Snake = function(str) {
  return str.replace(/([A-Z])/g, '-$1').toLowerCase();
};

/**
 * [snake2Camel 蛇形转驼峰]
 * @param  {String} str      [description]
 * @param  {Boolean} capLower [description]
 * @return {String}          [description]
 */
const snake2Camel = function(str, capLower) {
  let s = str.replace(/[-_](\w)/g, function(x) {
    return x.slice(1).toUpperCase();
  });
  s = s.replace(/^\w/, function(x) {
    return capLower ? x.toLowerCase() : x.toUpperCase();
  });
  return s;
};

const matchImg = imgs => {
  const b = /<img.*?(?:>|\/>)/gi;
  const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
  return imgs
    .match(b)
    .map(ele => {
      return ele.match(srcReg)?.[1];
    })
    .filter(a => a);
};
const urlFormat = url => {
  let formatedUrl = url || '';

  /* 处理前缀(协议等) */
  if (formatedUrl.indexOf('http//') == 0) {
    formatedUrl = `http://${formatedUrl.substr(6)}`;
  } else if (formatedUrl.indexOf('https//') == 0) {
    formatedUrl = `https://${formatedUrl.substr(7)}`;
    // eslint-disable-next-line no-empty
  } else if (formatedUrl.indexOf('http://') == 0 || formatedUrl.indexOf('https://') == 0) {
  } else {
    formatedUrl = `http://${formatedUrl}`;
  }

  /* 处理后缀(拓展名等) */
  const suffix = /.png.jpg$/i;
  if (suffix.test(formatedUrl)) {
    formatedUrl = formatedUrl.substr(0, formatedUrl.length - 4);
  }

  return formatedUrl;
};

const deepTableData = value => {
  const aa = [...value];
  let newLength = aa.length - 2;
  while (newLength >= 0) {
    aa[newLength] = aa[newLength].map(ele => {
      return _.flatMapDeep(aa[newLength + 1]).map(val => {
        return {
          value2: `${ele.value2},${val.value2}`,
        };
      });
    });
    newLength--;
  }

  return _.flattenDepth(aa[0]);
};

/**
 * 监测输入手机号正确与否
 * @param {string} phone 监测的手机号
 */
const telStr = phone => {
  return /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/.test(
    phone
  );
};

const getGroup = async () => {
  const { douyin, kuaishou } = await axios
    .get(`${baseUrl['v0']}/goods/categorys-filename`)
    .then(res => _.get(res, 'data.data'));
  const data = await Promise.all([
    axios.get(`${process.env.VUE_APP_JSTFILE}/${douyin}`).then(res => res.data),
    axios.get(`${process.env.VUE_APP_JSTFILE}/${kuaishou}`).then(res => res.data),
  ]);
  // const data = [null, null];
  return {
    DouYin: data[0],
    KuaiShou: data[1],
  };
};

const moreCategory = (data, options = {}) => {
  const source = options.source || 'cate_name';
  if (data[0]?.children?.length) {
    return data.map(ele => {
      const nowLabel = options.nowLabel ? `${options.nowLabel},${ele[source]}` : `${ele[source]}`;
      return moreCategory(ele.children, { source, nowLabel });
    });
  } else {
    return data.map(val => `${options.nowLabel},${val[source]}`);
  }
};

const flattenDepthMoreCategory = (data, options = {}) => {
  return _.flatMapDeep(moreCategory(data, options));
};

const multiplication = (value, percent = 100) => {
  // eslint-disable-next-line no-undef
  return numeral(value)
    .multiply(percent)
    .value();
};
const sum = arr => {
  // eslint-disable-next-line no-eval
  return eval(arr.join('+'));
};
// 列表组件价格formatter属性
const formatterPrice = val => (val / 100).toFixed(2) + '元';

const goodsPushStatus = status => {
  // eslint-disable-next-line object-curly-spacing
  return (
    {
      0: { label: '发布商品', disable: false },
      1: { label: '已发布', disable: false },
      2: { label: '发布中', disable: false },
      3: { label: '发布失败', disable: false },
      4: { label: '发布异常', disable: false },
      5: { label: '下架异常', disable: false },
      6: { label: '下架成功', disable: false },
    }[status] || {}
  );
};

const deepCopy = obj => {
  var result = Array.isArray(obj) ? [] : {};
  for (var key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object') {
        result[key] = deepCopy(obj[key]); // 递归复制
      } else {
        result[key] = obj[key];
      }
    }
  }
  return result;
};

const getQueryString = name => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
};

export {
  serialize,
  camel2Snake,
  snake2Camel,
  matchImg,
  urlFormat,
  deepTableData,
  telStr,
  getGroup,
  moreCategory,
  flattenDepthMoreCategory,
  multiplication,
  sum,
  formatterPrice,
  goodsPushStatus,
  deepCopy,
  getQueryString
};
