<template>
  <div class="list-table-wrapper column-text-general">
    <el-table
      ref="table"
      size="medium"
      :data="showData"
      @cell-click="cellClick"
      v-bind="$attrs"
      v-loading="loading"
      :default-sort="showDefaultSort"
      @sort-change="sortChange"
      @selection-change="$emit('selection-change', $event)"
      :stripe="stripe"
      :cell-class-name="cellClassName"
      :header-cell-class-name="cellClassName"
      highlight-current-row
    >
      <el-table-column v-if="showTwoRow">
        <template slot-scope="scope">
          <slot name="top" :row="scope.row" :$index="scope.$index" />
        </template>
      </el-table-column>
      <el-table-column
        v-if="selection"
        type="selection"
        :selectable="selectable"
        fixed="left"
        width="55"
        align="left"
      />
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        :align="item.align || 'left'"
        :class-name="item.className"
        :width="item.width"
        :min-width="item.minWidth"
        :sortable="item.sortable"
        :column-key="item.columnKey"
        :fixed="item.fixed"
        :type="item.type"
      >
        <template slot-scope="scope">
          <el-input
            v-if="item.type === 'input'"
            v-model="scope.row[item.prop]"
            :disabled="item.disabled || inputNotWrite"
            style="width: 90%"
          ></el-input>
          <slot v-else-if="item.slot" :name="item.slot" :row="scope.row" :$index="scope.$index" />
          <span v-else>
            {{ (item.formatter || (a => a))($_.get(scope.row, scope.column.property)) }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="showPagination" class="pagination-wrapper">
      <el-pagination
        background
        ref="paginat"
        @current-change="
          cpage => {
            this.handleCurrentChange(cpage);
          }
        "
        @size-change="handleSizeChange"
        :current-page="Number(paging.page) || 1"
        :page-sizes="[10, 15, 20, 50, 100]"
        :page-size="query.pageSize || 15"
        layout="total,sizes,prev,pager,next,slot,jumper"
        :total="Number(paging.total)"
        :page-count="paging.total_page || 1"
        prev-text="上一页"
        next-text="下一页"
      >
        <span class="page-total-wrapper">共{{ pageTotalCount }}页</span>
      </el-pagination>
    </div>
  </div>
</template>

<script>
const order = {
  ascending: 'desc',
  descending: 'asc',
  asc: 'descending',
  desc: 'ascending',
};
import { orderBy } from '@/helper/sort.js';
export default {
  name: 'ListTable',
  props: {
    /**
     * 是否显示斑马线
     */
    stripe: {
      type: Boolean,
      default: false,
    },
    /**
     * 请求接口的路径 是在api/rapis.js里的key
     */
    road: {
      type: String,
    },
    /**
     * 传进来的query参数，比如page和pageSize
     */
    otherQuery: {
      type: Object,
      required: false,
      default: () => {},
    },
    /**
     * columns 行上的列表，有label，prop和slot三个主要参数和width，formatter。label是显示在顶部的字，prop是这一列要显示的key，slot为自定义的template，width是宽度，formatter是当前这一列的格式化方法
     */
    columns: {
      type: Array,
      required: true,
    },
    /**
     * 是否显示Index，就是12345
     */
    showIndex: {
      type: Boolean,
      default: true,
    },
    /**
     * 是否显示分页
     */
    showPagination: {
      type: Boolean,
      default: true,
    },
    /**
     * 请求的在get里的version，基本都是v0
     */
    version: {
      type: String,
      default: 'v0',
    },
    /**
     * 整个数据格式化
     */
    formatData: {
      type: Function,
      default: val => val,
    },
    /**
     * 进行数据加载之后是否返回到页面顶部
     */
    goTop: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否需要显示error
     */
    throw: {
      type: Boolean,
      default: true,
    },
    // 是否需要展示message
    showMessage: {
      type: Boolean,
      default: false,
    },
    /**
     * 第一列的多选
     */
    selection: {
      type: Boolean,
      default: true,
    },
    /**
     * 设置复选框禁用
     */
    selectable: {
      type: Function,
    },
    /**
     * 数据来源 module 是这个模块请求send 是传进来
     * @values module，send
     */
    dataBy: {
      type: String,
      default: 'module',
    },
    /**
     * 当是send的时候需要从外部传入数据 paging里边必须要写pageSize
     */
    propsTableData: {
      type: Object,
      default: () => {
        return {
          data: [],
          paging: {},
        };
      },
    },
    /**
     * 是否每行显示两行数据
     */
    showTwoRow: {
      type: Boolean,
      default: false,
    },
    /**
     * 传进来的排序方式，现在还没有使用过整个方法，所以先不做介绍
     */
    defaultSort: {
      type: Object,
      default: () => {},
    },
    /**
     * 列class
     */
    cellClassName: {
      type: Function,
      default: () => '',
    },
    /**
     * propsData 封装请求的data，比如road的值为/a/{id}，这里就需要写上{id: 1}，请求的结果就是/a/1
     */
    propsData: {
      type: Object,
      default: () => {},
    },
    /**
     * 是否需要进行排序
     */
    needSort: {
      type: Boolean,
      default: false,
    },
    /**
     * index的自定义label
     */
    indexLabel: {
      type: String,
      default: '',
    },
    /**
     * index的width
     */
    indexWidth: {
      type: Number,
      default: 40,
    },
    /**
     * 请求到的数据是否进行回传
     */
    callback: {
      type: Boolean,
      default: false,
    },
    /**
     * 刷新 当refresh更改的时候  进行数据重新获取
     */
    refresh: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Boolean | String,
    },
    // 刷新当前页
    refreshNow: {
      type: Boolean,
    },
  },
  computed: {
    showDefaultSort() {
      if (!this.needSort) {
        return {};
      }
      return {
        ...this.defaultSort,
        order: order[this.defaultSort.order],
      };
    },
    paging() {
      return this.$_.get(this.tableData, 'paging', {});
    },
    showData() {
      return this.formatData(this.$_.get(this.tableData, 'data', []));
    },
    pageTotalCount() {
      return this.paging.total_page;
    },
    sortShowData() {
      // 传入进来的值发生了改变 修改了sort order query之后 自己在内部进行排序
      // query是get方法的query 包括sort order keyword page pageSize
      // 所以挡在底部的pageNum发生变化的时候  这里也会被修改  所以这里需要进行判断
      const { propsTableData = {}, sortOrder, query } = this;
      if (!this.$_.get(propsTableData, 'paging.total')) {
        return {};
      }
      let data;
      if (!this.needSort) {
        data = propsTableData.data;
      } else {
        data = orderBy(propsTableData.data, sortOrder.sort, sortOrder.order);
      }
      return {
        paging: {
          ...query,
          total: this.$_.get(data, 'length', 15),
        },
        data: this.$_.chunk(data, query.pageSize)[query.page - 1],
      };
    },
    inputNotWrite() {
      // 0是查看 不可以修改
      return this.disabled || this.$store.state.inputType === 0;
    },
  },
  watch: {
    otherQuery: {
      handler(newValue) {
        this.preOtherQuery = newValue;
      },
      deep: true,
    },
    sortOrder(newValue, oldValue) {
      this.query = {
        ...this.query,
        ...this.preOtherQuery,
        ...newValue,
        page: 1,
      };
      if (this.dataBy === 'module') {
        this.getData();
      }
    },
    // 传进来的值改变
    sortShowData(newValue, oldValue) {
      // 如果是传入进来的值有count 才需要进行修改tableData
      // 因为
      if (this.$_.get(this.propsTableData, 'paging.total')) {
        this.tableData = newValue;
      }
    },
    refresh(newValue) {
      if (typeof newValue === 'string') {
        this.query = {
          ...this.query,
          ...this.preOtherQuery,
          page: 1,
        };
      }
      this.getData();
    },
    // 刷新当前页
    refreshNow() {
      this.getData();
    },
  },
  data() {
    return {
      loading: false,
      order,
      // otherQuery预存值
      preOtherQuery: {},
      // 获取最基础的query
      query: this.getStartQuery(),
      // 基础的表格属性
      tableData: {
        data: [],
        paging: {},
      },
      sortOrder: this.defaultSort,
    };
  },
  mounted() {
    if (this.dataBy !== 'module') {
      this.tableData = this.sortShowData;
    }
    this.getData();
  },
  methods: {
    handleSizeChange(value) {
      this.setQuery({ pageSize: value });
    },
    cellClick(row) {
      /**
       * 进行每一行的多项选择的callback
       */
      this.$emit('cell-click', row);
    },
    // 获取初始的query
    getStartQuery() {
      if (this.needSort) {
        return {
          page: 1,
          pageSize: 15,
          ...this.defaultSort,
          order: order[this.defaultSort.order],
          ...this.otherQuery,
        };
      }
      return {
        page: 1,
        pageSize: 15,
        ...this.otherQuery,
      };
    },
    // 排序发生变化
    sortChange(value) {
      this.sortOrder = {
        ...value,
        sort: value.sort || value.prop,
        order: order[value.order],
      };
    },
    // 返回index的数字
    indexMethod(index) {
      return ((this.paging.page || 1) - 1) * (this.paging.pageSize || 15) + index + 1;
    },
    // 点击分页的切换事件
    handleCurrentChange(cpage) {
      this.setQuery({ page: cpage });
    },
    setQuery(newQuery) {
      this.query = {
        ...this.query,
        ...this.preOtherQuery,
        ...newQuery,
      };
      this.getData();
    },
    getData() {
      if (this.dataBy !== 'module') {
        return;
      }
      this.loading = true;
      if (this.goTop) {
        this.$goTop();
      }
      this.$get({
        road: this.road,
        query: {
          ...this.query,
          // order: this.query.order ? this.query.order.toLocaleUpperCase() : '',
        },
        data: this.propsData,
        version: this.$route.query.example ? 'example' : this.version,
        throw: this.throw,
      })
        .then(res => {
          this.loading = false;
          // 因为有的结构不一样 直接返回的数组  所以这里判断是不是有data的对象
          // 如果有 直接设置为res  不然设置为带paging的res
          // this.tableData = res.data ? res : { paging: {}, data: res?.data.goods };
          this.tableData = { paging: res?.data || {}, data: res?.data?.list || [] };
          if (res?.data?.list?.length === 0) {
            this.query.page = 1;
          }
          if (this.callback) {
            /**
             * 当有callback的时候进行的获取数据的抛会
             */
            this.$emit('callbackFun', this.tableData);
          }
        })
        .catch(err => {
          if (this.showMessage) {
            this.$message.error(err.message);
          }
          if (this.callback) {
            /**
             * 失败了也会返回失败
             */
            this.$emit('callbackFun', err);
          }
          this.loading = false;
        });
    },
    setData(data) {
      console.log('修改存储');
    },
  },
};
</script>

<style lang="less">
.list-table-wrapper {
  background: #fff;
}
.pagination-wrapper {
  margin-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .el-pagination {
    span,
    li {
      color: @color-text-regular;
    }
    button.btn-prev,
    button.btn-next {
      background-color: transparent !important;
    }
  }
}
</style>
