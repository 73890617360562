<!--  -->
<template>
  <span @click.stop="copy(content)">
    <i v-if="visible && !showContent" class="el-icon-document-copy copy-icon"></i>
    <font style="cursor: pointer;" v-if="showContent">{{ content || '无' }}</font>
  </span>
</template>

<script>
export default {
  props: {
    /**
     * 要被拷贝的文字
     */
    content: {
      type: String,
    },
    // 是否显示复制文字
    showContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },

  components: {},

  computed: {
    visible() {
      return !['', '无'].includes(this.content);
    },
  },

  mounted() {},

  methods: {
    copy(shareLink) {
      if (!shareLink) return;
      try {
        var input = document.createElement('input'); // 直接构建input
        input.value = shareLink; // 设置内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand('Copy'); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        this.$message.success('复制成功');
      } catch (err) {
        this.$message.warning('复制失败');
      }
    },
  },
};
</script>
<style lang="less" scoped>
.copy-icon {
  cursor: pointer;
  &:hover {
    color: blue;
    // font-size: 16px;
  }
}
</style>
