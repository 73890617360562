import { snake2Camel, camel2Snake } from '@/helper';
const Vue = require('vue');

// 获取需要动态创建的弹窗组件
const dialogsContext = require.context('@/dialogs', true, /@([a-zA-Z\-0-9]+)\.vue$/);
const dialogs = dialogsContext.keys().reduce((views, key) => {
  const fileName = key.match(/@([a-zA-Z\-0-9]+)\.vue$/i)[1];
  if (!fileName) return views;
  const componentName = camel2Snake(fileName);
  const clsName = snake2Camel(componentName);
  return Object.assign(views, { [clsName]: dialogsContext(key) });
}, {});

/**
 * [createDialog 创建弹窗组件]
 * @param  {[type]}   component [组件]
 * @param  {[type]}   data    [参数]
 * @return {[type]}             [description]
 */
const createDialog = function(temp, data, callback) {
  // 初始化配置参数
  const opt = {
    data,
    callback,
  };
  const component = Object.assign({}, temp);
  // const parent = this
  const initData = {
    visible: true,
  };
  Object.assign(initData, component.data());
  opt.data && Object.assign(initData, JSON.parse(JSON.stringify(opt.data)));
  component.data = function() {
    return initData;
  };
  // 创建构造器创建实例挂载
  const DialogC = Vue.extend(component);
  const dialog = new DialogC();
  // 关闭事件
  const _onClose = dialog.$options.methods.onClose;
  dialog.$watch('visible', function(n, o) {
    dialog === false && dialog.onClose();
  });
  dialog.onClose = function() {
    dialog.$destroy();
    _onClose && _onClose.call(dialog);
    document.body.removeChild(dialog.$el);
  };
  // 回调事件
  const _onCallback = dialog.$options.methods.onCallback;
  dialog.onCallback = function(...arg) {
    try {
      _onCallback && _onCallback();
      if (callback && typeof callback === 'function') {
        callback.call(this, ...arg, dialog);
      }
      // dialog.onClose()
    } catch (e) {
      console.log(e);
    }
  };
  dialog.$mount();
  dialog.$watch('visible', function(n, o) {
    dialog === false && dialog.onClose();
  });
  document.body.appendChild(dialog.$el);
};

/**
 * [createDialogAsync promise创建弹窗]
 * @param  {[type]}   temp     [description]
 * @param  {[type]}   data     [description]
 * @param  {Function} callback [description]
 * @return {[type]}            [description]
 */
function createDialogAsync(temp, data) {
  return new Promise(function(resolve, reject) {
    // 初始化配置参数
    const opt = {
      data,
    };
    const component = Object.assign({}, temp);
    // const parent = this
    const initData = {
      visible: true,
    };
    Object.assign(initData, component.data());
    opt.data && Object.assign(initData, JSON.parse(JSON.stringify(opt.data)));
    component.data = function() {
      return initData;
    };
    // 创建构造器创建实例挂载
    const DialogC = Vue.extend(component);
    const dialog = new DialogC();
    // 关闭事件
    const _onClose = dialog.$options.methods.onClose;
    dialog.onClose = function() {
      resolve();
      dialog.$destroy();
      _onClose && _onClose.call(dialog);
      document.body.removeChild(dialog.$el);
    };
    // 回调事件
    const _onCallback = dialog.$options.methods.onCallback;
    dialog.onCallback = function(...arg) {
      try {
        _onCallback && _onCallback();
        resolve(...arg);
        dialog.$destroy();
        _onClose && _onClose.call(dialog);
        document.body.removeChild(dialog.$el);
      } catch (e) {
        console.log(e);
      }
    };
    dialog.$mount();
    dialog.$watch('visible', function(n, o) {
      dialog === false && dialog.onClose();
    });
    document.body.appendChild(dialog.$el);
  });
}

// 弹窗类组件封装
// function encComponent () {
// }

/**
 * [init 初始化]
 * @param  {[type]} components [description]
 * @return {[type]}            [description]
 */
function init(values) {
  const dialogComponents = {};
  if (!values) return;
  Object.keys(values).forEach(name => {
    const ComponentContext = values[name].default;
    dialogComponents[name] = function(data, callback) {
      if (callback && typeof callback === 'function') {
        return createDialog.call(this, ComponentContext, data, callback);
      }
      return createDialogAsync.call(this, ComponentContext, data);
    };
  });
  return dialogComponents;
}

Vue.prototype.$dialog = init(dialogs);

// export default dialogs
