<template>
  <el-dialog :visible.sync="visible" :close-on-click-modal="false" class="imageZoomIn">
    <el-carousel
      :arrow="imageData.length > 1 ? 'always' : 'never'"
      :height="'500px'"
      :initial-index="index"
      :autoplay="false"
    >
      <el-carousel-item v-for="item in imageData" :key="item">
        <div class="zoomIn-imgWrap">
          <div class="zoomIn-imgContent">
            <img :src="item" alt="" />
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      imageData: [],
      index: 0,
    };
  },
  created() {
    console.log();
  },
  methods: {
    onClose() {},
  },
};
</script>
<style lang="less">
.imageZoomIn {
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__header {
    padding: 0;
    position: absolute;
    left: 50%;
    bottom: -100px;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: #fff;
    .el-dialog__headerbtn {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 30px;
      padding: 8px 10px;
    }
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  .el-carousel__item {
    overflow: auto;
  }
  .zoomIn-imgWrap {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: table;
  }
  .zoomIn-imgContent {
    height: 100%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    overflow: auto;
  }
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}
</style>
