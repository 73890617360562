export default {
  // 账号
  // 登录
  login: '/account/login',
  // 注册
  register: '/account/register',
  // 检查账号存在
  accountCheck: '/account/check',
  // 重置密码
  accountReset: '/account/reset',
  // 发送短信验证码
  validateCode: '/account/send-validate-code',
  // 获取账户信息
  accountInfo: '/account/account-info',

  // 品牌数据大脑
  // 商品数据get
  brainGoods: '/merchant/brand/brain-goods',
  // 店铺数据get
  brainShop: '/merchant/brand/brain-shop',
  // 获取店铺列表
  getShops: '/merchant/brand/get-shops',

  // 均瑶
  // 商品数据get
  jybrainGoods: '/merchant/bdbrand/brain-goods',
  // 店铺数据get
  jybrainShop: '/merchant/bdbrand/brain-shop',
  // 获取店铺列表
  jygetShops: '/merchant/bdbrand/get-shops',

  // 商家选品池
  cpProductList: '/merchant/cp-product/list',
  cpProductAdd: '/merchant/cp-product/add',
  cpProductCategory: '/merchant/cp-product/backend-category',
  cpProductDetail: '/merchant/cp-product/detail',

  // 获取微信二维码
  weChatLogin: 'account/we-chat-login',
  // 获取tOken
  accountToken: '/account/token',
};
