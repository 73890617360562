// 注入全局的方法
import { get, post, del, put, patch } from '@/api/index';
import {
  urlFormat,
  moreCategory,
  flattenDepthMoreCategory,
  multiplication,
  sum,
} from '@/helper/index.js';
const moment = require('moment');
const _ = require('lodash');

const plugins = {
  // 注册
  install(Vue) {
    Vue.prototype.$get = get;
    Vue.prototype.$post = post;
    Vue.prototype.$del = del;
    Vue.prototype.$put = put;
    Vue.prototype.$patch = patch;
    Vue.prototype.$urlFormat = urlFormat;

    Vue.prototype.$moment = moment;
    Vue.prototype.$_ = _;
    Vue.prototype.$moreCategory = moreCategory;
    Vue.prototype.$flattenDepthMoreCategory = flattenDepthMoreCategory;
    Vue.prototype.$multiplication = multiplication;
    Vue.prototype.$sum = sum;
  },
};
export default plugins;
