export default [
  {
    path: '/',
    name: 'merchantData',
    meta: {
      title: '首页',
    },
    component: () => import('@/pages/home/index.vue'),
  },
  // {
  //   path: '/select-pool',
  //   name: 'selectPool',
  //   meta: {
  //     title: '商家选品池',
  //   },
  //   component: () => import('@/pages/brand/selectPool/index.vue'),
  // },
];
