import _axios from './axios';
const _ = require('lodash');
import baseUrl from './road';
import apis from './apis.js';
const methods = ['get', 'post', 'delete', 'put', 'patch'];

export const [get, post, del, put, patch] = methods.map(action => {
  return val => {
    const url = reqRoad(val.road, val.params, val.version);
    // 如果是返回blob数据的接口就在新页面打开请求地址
    if (val.dataType === 'link') {
      return window.open(val.query ? `${url}?${serialize(val.query)}` : url, '_blank');
    }
    return new Promise((resolve, reject) => {
      _axios({
        ...val,
        ...{
          method: action,
          url,
          data: val.data || null,
          params: val.query,
          headers: val.headers || {},
          extra: val.extra || {},
          responseType: val.responseType || 'json',
        },
      })
        .then(res => {
          if (res) resolve(res.data);
        })
        .catch(err => {
          reject({
            code: _.get(err, 'response.status'),
            response: _.get(err, 'response.data'),
          });
        });
    });
  };
});

const parseUrl = (url, params) => {
  return (
    url &&
    url.replace(/\{(\w+)\}/g, (m, n) => {
      return params[n];
    })
  );
};
export const serialize = function(obj) {
  var str = [];
  for (var p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p) && obj[p]) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }
  return str.join('&');
};
const reqRoad = (road, params, version) => {
  return baseUrl[version] + parseUrl(apis[road], params);
};
