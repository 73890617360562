<template>
  <div>
    <el-radio-group
      size="small"
      :name="name"
      v-model="propValues"
      v-if="type === 'radio'"
      v-bind="$attrs"
      :class="propsClass"
      :disabled="inputNotWrite"
    >
      <el-radio
        v-for="(item, index) in showOptions"
        :key="index"
        :label="$_.get(item, value)"
        :disabled="item.disabled"
      >
        {{ $_.get(item, source) }}
      </el-radio>
    </el-radio-group>
    <el-checkbox-group
      size="small"
      :name="name"
      v-model="propValues"
      v-if="type === 'checkbox'"
      v-bind="$attrs"
      :class="propsClass"
      :disabled="inputNotWrite"
    >
      <el-checkbox
        v-for="(item, index) in showOptions"
        :key="index"
        :label="`${$_.get(item, value)}`"
        :disabled="item.disabled"
      >
        {{ $_.get(item, source) }}
      </el-checkbox>
    </el-checkbox-group>
    <el-input
      size="small"
      :name="name"
      clearable
      v-model="propValues"
      v-if="type === 'input'"
      v-bind="$attrs"
      :disabled="inputNotWrite"
      @keyup.enter.native="tableSelectsKeyDown"
      @blur="blur"
    ></el-input>
    <el-input
      size="small"
      :name="name"
      clearable
      type="textarea"
      v-model="propValues"
      v-if="type === 'textarea'"
      v-bind="$attrs"
      :disabled="inputNotWrite"
      @blur="blur"
    ></el-input>
    <el-select
      size="small"
      :name="name"
      v-model="propValues"
      v-if="type === 'select'"
      v-bind="$attrs"
      clearable
      :disabled="inputNotWrite"
      @change="relevanceChange"
    >
      <el-option
        v-for="item in showOptions"
        :key="item.value"
        :label="`${$_.get(item, source)}`"
        :value="`${$_.get(item, value)}`"
      ></el-option>
    </el-select>
    <el-cascader
      size="small"
      :name="name"
      clearable
      v-model="propValues"
      v-if="type === 'cascader'"
      v-bind="$attrs"
      :options="options"
      :disabled="inputNotWrite"
      :key="options.toString()"
      :props="extra"
    ></el-cascader>
    <el-switch
      size="small"
      :name="name"
      v-model="propValues"
      v-if="type === 'switch'"
      v-bind="$attrs"
      :disabled="inputNotWrite"
    ></el-switch>
    <el-time-select
      size="small"
      :name="name"
      v-model="propValues"
      v-if="type === 'timeSelect'"
      v-bind="$attrs"
      :disabled="inputNotWrite"
    ></el-time-select>
    <el-date-picker
      size="small"
      :name="name"
      v-if="type === 'datetimerange'"
      v-model="propValues"
      type="datetimerange"
      format="yyyy-MM-dd HH:mm"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      @change="relevanceChange"
    ></el-date-picker>
  </div>
</template>

<script>
export default {
  /**
   * v-mode的值修改
   */
  model: {
    prop: 'formValues',
    event: 'change',
  },
  props: {
    // 输入框name属性
    name: {
      type: String,
    },
    /**
     * 绑定的值 v-model
     */
    formValues: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Boolean | Array,
      require: true,
    },
    /**
     * 传递下来的class
     */
    propsClass: String,
    /**
     * 表单的类型 radio 单选 checkbox 多选 input 输入 select 选择 cascader 卡片 switch 切换 timeSelect 时间选择
     * @values radio, checkbox, input, select, cascader, switch, timeSelect,
     */
    type: {
      type: String,
      required: true,
    },
    /**
     * 当是多选框的时候需要传递下来
     * 每一个obj的key有source，label，disables, enable
     */
    options: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Array | Object,
      default: () => [],
    },
    /**
     * item的labelSource
     */
    source: {
      type: String,
      default: 'label',
    },
    /**
     * item的valueSource
     */
    value: {
      type: String,
      default: 'value',
    },
    /**
     * 有的表单是有单位的，比如¥
     */
    lastUnit: {
      type: String,
      default: '',
    },
    /**
     * 是否禁用
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * 关联的查询属性
     */
    relevance: {
      type: Array,
      default: () => [],
    },
    /**
     * 是否一直可以编辑
     */
    alwaysWrite: {
      type: Boolean,
      default: false,
    },
    // 是否有多余的
    extra: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    showOptions() {
      if (this.options && this.options.length) {
        return this.options.filter(ele => ele.enable !== 0);
      }
      return [];
    },
    inputNotWrite() {
      // 0是查看 不可以修改
      return this.alwaysWrite ? false : this.disabled || this.$store.state.inputType === 0;
    },
    showOptionsObj() {
      const obj = {};
      this.showOptions.forEach(ele => {
        obj[ele[this.value]] = ele;
      });
      return obj;
    },
  },
  data() {
    return {
      propValues: this.formValues,
    };
  },
  inject: {
    relevanceHandler: {
      from: 'relevanceHandler',
      default: () => {},
    },
    tableSelectsKeyDown: {
      from: 'tableSelectsKeyDown',
      default: () => {},
    },
  },
  watch: {
    propValues(newValue, oldValue) {
      this.$emit('change', newValue);
      if (this.showOptions.length) {
        this.$emit('objChange', this.showOptionsObj[newValue]);
      }
    },
    formValues(newValue) {
      this.propValues = newValue;
    },
  },
  destroy() {
    this.$store.commit('INPUTUNNOTWRITE', { type: -1 });
  },
  methods: {
    // 关联属性改变处理
    relevanceChange(val) {
      if (this.relevance.length) {
        return this.relevanceHandler(this.relevance, val);
      }
    },
    blur(val) {
      this.$emit('blur', val);
    },
  },
};
</script>

<style lang="less">
.el-select {
  width: 100%;
}
</style>
