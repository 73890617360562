<template>
  <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false" width="550px">
    <el-form
      :model="formData"
      status-icon
      :rules="rules"
      ref="formData"
      label-width="100px"
      class="formData"
    >
      <el-form-item prop="taobao_item_id" label="商品id">
        <el-input
          name="taobao_item_id"
          class="login-inpt"
          style="width: 350px; text-align: center"
          type="text"
          v-model="formData.taobao_item_id"
          placeholder="商品id"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item prop="price" :label="priceName">
        <el-input-number
          name="price"
          v-model="formData.price"
          controls-position="right"
          placeholder="请输入价格"
          :precision="2"
          :min="1"
          style="width: 190px"
        ></el-input-number>
        元
      </el-form-item>
      <el-form-item prop="star_fee_rate" label="主播佣金">
        <el-input-number
          name="star_fee_rate"
          v-show="false"
          v-model="formData.star_fee_rate"
          controls-position="right"
          placeholder="请输入主播佣金"
          :precision="0"
          :min="1"
          :max="100"
          style="width: 190px"
        ></el-input-number>
        <span>1%</span>
      </el-form-item>
      <el-form-item prop="service_fee_discount" label="服务费折扣">
        <el-select
          name="service_fee_discount"
          v-model="formData.service_fee_discount"
          placeholder="请选择服务费折扣"
          style="width: 190px"
        >
          <el-option
            v-for="item in options"
            :key="item"
            :label="item + '折'"
            :value="item"
          ></el-option>
        </el-select>
        <span class="service-charge">{{ `技术服务费：${serviceFeeRate}%` }}</span>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onCallback(false)">{{ cancelText }}</el-button>
      <el-button type="primary" @click="submitForm('formData')">{{ confirmText }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '导入商品',
      priceName: '十二仓售价',
      formData: {},
      confirmText: '确定',
      cancelText: '取消',
      options: [],
      serviceFeeRate: '',
      rules: {
        taobao_item_id: [{ required: true, message: '商品id不能为空' }],
        price: [{ required: true, message: '价格不能为空' }],
        service_fee_discount: [{ required: true, message: '服务费折扣不能为空' }],
      },
    };
  },
  created() {
    this.$get({
      road: 'pullConfig',
      version: 'v0',
    }).then(res => {
      this.options = res?.data?.service_fee_discount;
      this.serviceFeeRate = res?.data?.service_fee_rate;
    });
    this.formData.star_fee_rate = 1;
  },
  methods: {
    onClose() {},
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = JSON.parse(JSON.stringify(this.formData));
          // eslint-disable-next-line no-undef
          params.price = numeral(this.formData.price)
            .multiply(100)
            .value();
          console.log(this.formData, params);
          this.$post({
            road: 'pullTaobao',
            data: params,
            version: 'v0',
          }).then(res => {
            if (res.error_code === 0) this.onCallback(true);
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.service-charge {
  margin-left: 50px;
}
</style>
