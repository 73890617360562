const Vue = require('vue');
import baseUrl from './api/road';
import App from './App.vue';
import router from './router';
import store from './store';
import methods from './plugins/methods';
import components from './plugins/components';
import 'static/font/iconfont.css';
import 'static/style/index.less';
import '@/plugins/dialog';
import '@/permission';

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.use(methods);
// if (process.env.MIX_EXTERNAL !== 'true') { // **注意是字符串**
//   Vue.use(Element, { size: 'mini' });
// }
Vue.prototype.$ELEMENT = { size: 'mini', zIndex: 2000 };
Vue.prototype.$url = baseUrl['v0'];
Vue.use(components);
var vm = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

router.afterEach((to, from) => {
  vm.$el.scrollTop = 0;
});
