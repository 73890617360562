const isProduction = process.env.VUE_APP_STATIC === 'production';
const isTest = process.env.VUE_APP_STATIC === 'test';
const host = 'http://api.12cang.com/frontend';
const hostTest = 'http://testapi.12cang.com/frontend';
const baseUrl = {
  v0: isProduction ? host + '' : isTest ? hostTest + '' : '/frontend',
  v1: isProduction ? host + '' : isTest ? hostTest + '' : '/frontend/v1',
};

export default baseUrl;
