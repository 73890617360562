'use strict';

const axios = require('axios');
import store from '@/store';
import router from '@/router';

const { Message } = ELEMENT;

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

const PRODUCT_ENV = process.env.VUE_APP_ENV ? process.env.VUE_APP_ENV : process.env.NODE_ENV;
const baseUrl = process.env[`VUE_APP_${PRODUCT_ENV}Api`];
window.baseUrl = baseUrl;
const config = {
  baseURL: baseUrl,
  headers: {
    // 'Content-Type': 'application/json;charset=utf-8'
    // 'content-Type': 'multipart/form-data'
  },
  timeout: 60 * 1000, // Timeout
};

const _axios = axios.create(config);

const pending = {};
const CancelToken = axios.CancelToken;
const removePending = (key, isRequest = false) => {
  if (pending[key] && isRequest) {
    pending[key]('取消重复请求');
  }
  delete pending[key];
};
const getRequestIdentify = (config, isReuest = false) => {
  let url = config.url;
  if (isReuest) {
    url = config.baseURL + config.url.substring(1, config.url.length);
  }
  return config.method === 'get'
    ? encodeURIComponent(url + JSON.stringify(config.params))
    : encodeURIComponent(config.url + JSON.stringify(config.data));
};

const returnValue = val => {
  return val === 0 ? 0 : !!val;
};

// 请求拦截器
_axios.interceptors.request.use(
  config => {
    // 拦截重复请求(即当前正在进行的相同请求)
    const requestData = getRequestIdentify(config, true);
    removePending(requestData, true);

    config.cancelToken = new CancelToken(c => {
      pending[requestData] = c;
    });
    // const href = window.location.href
    const urls = config.url;
    if (urls === '/sys/addDepartment' || urls === '/sys/deleteDpt') {
      config.headers['content-Type'] = 'application/x-www-form-urlencoded';
      // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
    }
    // 'content-Type': 'multipart/form-data'
    // config['Content-Type'] = 'application/json;charset=utf-8'
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

_axios.interceptors.request.use(
  request => {
    const token = localStorage.getItem('token');
    request.headers.authorization = 'Bearer ' + token;

    let params = {};

    if (request.params) {
      Object.keys(request.params)
        .filter(key => returnValue(request.params[key]))
        .forEach(key => {
          params[key] = request.params[key];
        });
    } else {
      params = request.params;
    }
    request.params = params || {};
    if (Object.prototype.toString.call(request?.data).slice(8, -1) === 'FormData') {
      request.headers['Content-Type'] = 'multipart/form-data';
    }
    return request;
  },
  err => Promise.reject(err)
);

// Add a response interceptor
_axios.interceptors.response.use(
  response => {
    const extra = response.config.extra;
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data;
    // 这个状态码是和后端约定的
    const { error_code, msg } = dataAxios;
    const contentType = response.headers['content-type'];
    // 根据 error_code 进行判断
    if (response.config.responseType === 'blob') {
      if (contentType.slice(0, 46) === 'application/vnd.openxmlformats-officedocument.') {
        // 返回office文件blob数据,下载文件
        const title = extra.downloadTitle;
        const type = contentType.slice(46);
        const Blob2ImageFileForWXBrowser = type => {
          const blob = new Blob([dataAxios], { type: contentType });
          const fileName = `${title}.${type}`;
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            // 释放内存
            window.URL.revokeObjectURL(link.href);
            link = null;
          }
        };
        switch (type) {
          case 'wordprocessingml.document':
            return Blob2ImageFileForWXBrowser('docx');
          case 'wordprocessingml.template':
            return Blob2ImageFileForWXBrowser('dotx');
          case 'presentationml.presentation':
            return Blob2ImageFileForWXBrowser('pptx');
          case 'presentationml.slideshow':
            return Blob2ImageFileForWXBrowser('ppsx');
          case 'presentationml.template':
            return Blob2ImageFileForWXBrowser('potx');
          case 'spreadsheetml.sheet':
            return Blob2ImageFileForWXBrowser('xlsx');
          case 'spreadsheetml.template':
            return Blob2ImageFileForWXBrowser('xltx');
        }
      } else if (contentType.slice(0, 16) === 'application/json') {
        // 预期返回Blob数据的接口返回json时
        const reader = new FileReader();
        reader.readAsText(dataAxios, 'utf-8');
        reader.onload = function(e) {
          const res = JSON.parse(reader.result);
          if (res.error_code === 0) {
            Message.success(res.msg);
            if (extra.confirm) extra.confirm();
          } else {
            Message.error(res.msg);
          }
        };
      }
    }
    if (error_code === undefined) {
      // 如果没有 error_code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
      return dataAxios;
    } else {
      // 有 error_code 代表这是一个后端接口 可以进行进一步的判断
      switch (error_code) {
        case 0:
          // [ 示例 ] error_code === 0 代表没有错误
          return Promise.resolve(response);
        case 20001:
          // router.push('/login')
          break;
        case 70010:
          return Promise.resolve(response);
        case 70015:
          return Promise.resolve(response);
        case 2:
          return Promise.resolve(response);
        case 401:
          store.commit('SET_TOKEN', null);
          localStorage.setItem('token', '');
          router.go(0);
          return Promise.resolve(response);
        default:
          // 不是正确的 error_code
          if (!extra.unShowErrMsg) Message.error(msg);
          return Promise.resolve(response);
      }
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = '请求错误';
          break;
        case 401:
          error.message = '未授权，请登录';
          break;
        case 403:
          error.message = '拒绝访问';
          break;
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break;
        case 408:
          error.message = '请求超时';
          break;
        case 500:
          error.message = '服务器内部错误';
          break;
        case 501:
          error.message = '服务未实现';
          break;
        case 502:
          error.message = '网关错误';
          break;
        case 503:
          error.message = '服务不可用';
          break;
        case 504:
          error.message = '网关超时';
          break;
        case 505:
          error.message = 'HTTP版本不受支持';
          break;
        default:
          break;
      }
      Message.error(error.message);
    }
    return Promise.reject(error);
  }
);

export default _axios;
