<template>
  <div class="pic-box" :style="{ width: width + 'px' }">
    <div class="pic-wrap" :class="{ 'pic-rect': modal === 'rect' }">
      <div
        class="pic-content"
        @mouseenter="() => (handlerShow = true)"
        @mouseleave="() => (handlerShow = false)"
      >
        <img :src="url" alt="" @click="imageZoomIn(url)" />
        <div class="handlers" v-show="handlerShow">
          <slot name="handlers"></slot>
        </div>
      </div>
    </div>
    <slot name="info" v-if="showInfo" />
  </div>
</template>

<script>
export default {
  name: 'Picture',
  props: {
    // 图片数组
    list: {
      type: Array,
    },
    // 当前图片地址
    url: {
      type: String,
      default: '',
    },
    // 当前图片index
    index: {
      type: Number,
    },
    // 图片宽度
    width: {
      type: Number,
      default: 160,
    },
    // 是否显示其他信息
    showInfo: {
      type: Boolean,
      default: false,
    },
    // 图片显示模式
    modal: {
      type: String,
      default: 'rect',
    },
  },
  data() {
    return {
      handlerShow: false,
    };
  },
  methods: {
    imageZoomIn(img) {
      this.$dialog.ImgZoomIn({ imageData: this.list || [img], index: this.index });
    },
  },
};
</script>

<style>
.pic-box {
  width: 160px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.pic-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.pic-content {
  background: #ddd;
  min-height: 38px;
}
.pic-content img {
  display: block;
  cursor: pointer;
}
.pic-rect {
  padding-bottom: 100%;
}
.pic-rect .pic-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pic-rect .pic-content img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pic-content .handlers {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 38px;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.pic-content .handlers .iconfont {
  font-size: 24px;
  color: #ddd;
  cursor: pointer;
}

.pic-box .pic-info {
  margin-top: 5px;
}

.pic-box .pic-info .title {
  line-height: 20px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
