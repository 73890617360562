<template>
  <div class="basic-line">
    {{ showTitle }}
  </div>
</template>
<docs>
  <BasicLine showTitle='title' />
</docs>
<script>
export default {
  name: 'BasicLine',
  props: {
    /**
     * 基本线，线上的title
     */
    showTitle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="less" scoped>
.basic-line {
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin-bottom: 14px;
  background: #f7f7f7;
  padding-left: 10px;
}
</style>
